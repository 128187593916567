import React , {useState , useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../../../../components/firebase'
import { AuthContext} from '../../../../context/auth'
import {navigate} from 'gatsby'
import { Grid , TextField , Button , Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));


const Form = () => {
  const classes = useStyles();

  const [ data , setData] = useState({
    email : "",
    password:"",
    error: null,
})
const {setUser} = useContext(AuthContext)

  


  const handleChange = e=>{
    setData ({...data,[e.target.name]:e.target.value})
}


    const handleSubmit = async e =>{
      e.preventDefault()
  
      try{
  const result = await firebase
  .auth()
  .signInWithEmailAndPassword(data.email , data.password)
  setUser(result)
  navigate('/coworking')
  
      }catch(err){
          setData({...data ,error: err.message})
  
      }
  }

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
             
              onChange={handleChange}
              type="email"
              value = {data.email} 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Password"
              label="Password *"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
         
              onChange={handleChange}
              type="password"
              value = {data.password}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Fields that are marked with * sign are required.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
         
            >
           sign in
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
           
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
